.erpfaq {
  &__banner {
    position: relative !important;
    img {
      width: 100%;
      height: 450px;
      object-fit: cover;
    }
    &--content {
      position: absolute;
      top: 40%;
      @include responsive-for(md){
        top: 30%;
      }
      @include responsive-for(xs){
        top: 18%;
      }
      h3 {
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        text-align: center;
        color: color(primary, base);
        @include responsive-for (md){
          line-height: 50px;
        }
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #ffffff;
        width: 52%;
        margin: 0 auto;
        margin-top: 20px; 
        @include responsive-for(md){
          width: 80%;
        }
      }
    }
  }
  &__faq {
    margin: 60px 0;
    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.02em;
      color: color(secondary, base);
    }
    .accordion-button {
      background-color: #fafafa !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #1e1e20 !important;
      margin-bottom: 12px;
    }
    .accordion-item {
      border: none;
    }
    .accordion-body {
      background-color: #fafafa !important;
      margin-bottom: 12px;
    }
  }
  &__form {
    background-color: #e4faf7;
    padding: 60px 0;
    label {
      position: relative;
      .asterik {
        position: absolute;
        font-size: 8px;
        color: red;
      }
    }
    h4 {
      @include headingh4;
      text-align: center;
      &::after {
        @include pseudoElement;
        width: 110px;
        right: 412px;
        top: 30px;
        @include responsive-for(md){
          right: 113px;
        }
      }
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #131d24;
      margin-bottom: 60px;
    }
    button{
        @include Button;
        margin-top: 0;

    }
    .form-control{
        background-color:#E4FAF7 !important;
    }
  }
}
.accordinerpfaq-list{
  padding-left: 0px;
  margin-top: 15px;
  list-style-type: disc;
  margin-left: 40px;
}
.minimum-recommended{
  list-style-type:disc;
}