.contact {
  &__banner {
    img {
      width: 100%;
      height: 465px;
      object-fit: cover;
      position: relative;
      @include responsive-for(md) {
        height: 350px;
      }
    }
    &--content {
      position: absolute;
      text-align: center;
      top: 40%;
      left: 20%;
      @include responsive-for(md) {
        top: 60%;
        left: 0;
      }
      @include responsive-for(xs) {
        top: 24%;
        left: 0;
      }
      h3 {
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        text-align: center;
        color: color(primary, base);
        margin-bottom: 20px;
        @include responsive-for(md) {
          line-height: 50px;
        }
      }
      p {
        width: 80%;
        margin: 0 auto;
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #ffffff;
      }
    }
  }
  &__formmap {
    margin: 60px 0;
    label {
      position: relative;
      .asterik {
        position: absolute;
        font-size: 8px;
        color: red;
      }
    }
    button {
      @include Button;
      width: 100%;
      margin-top: 0;
      &:hover {
        background: color(primary, base);
      }
    }
    iframe {
      width: 100%;
      height: 450px;
      @include responsive-for(md) {
        margin-top: 40px;
      }
    }
  }
}

.error {
  color: color(danger, base);
}

.success {
  background: #000000ab;

  display: inline-block;
  padding: 1rem;
  border-radius: 2rem;
  color: white;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  animation: success 1s ease;
  z-index: 999999999999999999999;
}
@keyframes success {
  from {
    top: -100%;
  }
  to {
    top: 5%;
  }
}
