.navbar {
  padding: 15px 0 !important;
    .active-link.active{
        color: #24BCAA !important;
        position: relative;
         &::before{
            content:"";
            position:absolute;
            background-color: #24BCAA;
            width: 30px;
            height: 1.5px;
            top: 33px;
         }
    }
  .navbar-nav a {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #131d24 !important;
    margin-left: 50px;
  }
}
.navbar-nav{
  position: relative;
}
.navbarDropdown {
  background-color: #2e4859;
  padding: 28px 31px;
  position: absolute;
  z-index: 99999;
  width: 80%;
  @include responsive-for(md){
    width: 100%;
    top: 128px;
    left: 0;
    right: 0;
  }
  &__heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: color(primary, base);
    border-bottom: 0.5px solid #e0e0e0;
    padding-bottom: 10px;
    text-transform: uppercase;
  }
  ul {
    padding-left: 0;
    li {
        padding-bottom: 8px;
      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #ffffff !important;
      }
    }
  }
  &__arrowup{
    position: absolute;
    right: 35%;
    width: 20px;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 15px solid #2e4859;
    top: -2%;
    @include responsive-for(md){
      display: none;
    }
  }
}
@media (min-width: 768px){
.navbar-expand-md .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
    justify-content: flex-end;
}
.smallnavbar{
  display: none !important;
}
}

@media (max-width:768px){
  .navbar{
    display: none !important;
  }
}
.smallnavbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  &__openicon{
    margin-right: 20px;
    font-size: 28px;
    color: #24BCAA;
    border: 2px solid #24BCAA;
    padding: 4px;
    border-radius: 20%;
  }
  &__display{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 99999;
    img{
      position: absolute;
      left: 20px;
      top: 20px;
    }
    &--crossicon{
      position: absolute;
      right: 30px;
      top: 40px;
      color: #24BCAA;
      border: 2px solid #24BCAA;
      padding: 5px;
      border-radius: 50%;
      font-size: 25px;
    }
    &--mainul{
      margin-top: 100px;
      border-top: 2px solid #24BCAA;
    padding-top: 10px;
      li{
        padding: 10px 0;
      }
    }
    &--mainul-subul{
      li{
        padding: 5px 0;
        position: relative;
        a{
          height: 1px;
          color: #000 !important;
          &::after{
            content: "";
            background-color: rgba(36, 188, 170, 0.51);
            height: 2px;
            width: 35px;
            position: absolute;
            top: 25px;
            left: 0;
          }
        }
      }
    }
  }
}