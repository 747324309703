.home {
  &__banner {
    border-top: 2px solid #24bcaa;
    background-color: #e4faf7;
    padding: 110px 0 140px 0;
    @include responsive-for(md) {
      padding: 50px 0 60px 0;
    }
    .col-md-7 {
      position: relative !important;
    }
    img {
      width: 105%;
      top: -20%;
      position: absolute !important;
      right: -10%;
      @include responsive-for(tab) {
        right: 0;
      }
      @include responsive-for(md) {
        position: unset !important;
        margin-top: 30px;
      }
      @include responsive-for(xs){
        width: 100% !important;
        right: 0;
      }
    }
    &--heading {
      font-weight: 700 !important;
      font-size: 43px;
      line-height: 60px;
      color: #2e4859;
      span {
        color: color(primary, base);
      }
      @include responsive-for(md){
        line-height: 50px;
      }
    }
    &--paragraph {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #131d24;
      margin-top: 17px;
    }
    a {
      @include Button;
      &:hover {
        background: color(primary, base);
      }
    }
  }
  .home-Banners {
    background-color: #fff !important;
  }
  &__erpneed {
    margin-top: 60px;
    h4 {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: color(secondary, base);
      position: relative;
      &::after {
        @include pseudoElement;
        top: 32px;
        left: 47.9%;
        @include responsive-for(md){
          left: 46.2%;
        }
      }
    }
    &--subtitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #131d24;
      margin-bottom: 80px;
    }
    &--subtitle-needtitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.02em;
      color: color(secondary, base);
      margin: 15px 0;
    }
    &--subtitle-desc {
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      letter-spacing: 0.02em;
      color: #1e1e20;
      height: 85px;
    }
    img {
      height: 40px;
      width: 30px;
    }
    a {
      color: color(primary, base);
      font-weight: 700;
      font-size: 16px;
    }
  }

  &__irdverified {
    margin-top: 70px;
    background-color: #e4faf7;
    padding: 40px 0;

    h4 {
      font-weight: 700;
      font-size: 30px;
      line-height: 48px;
      color: color(secondary, base);
      position: relative;
      margin-top: 30px;
      &::after {
        @include pseudoElement;
        left: 0px;
        top: 30px;
        @include responsive-for(md){
          width: 168px;
        }
      }
    }
    p {
      margin-top: 30px;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.02em;
      color: #131d24;
    }
  }
  &__betterdecisions {
    padding-top: 50px;
    @include responsive-for(md){
      padding-bottom: 30px;
    }
    h4 {
      font-weight: 700;
      font-size: 30px;
      line-height: 48px;
      color: color(secondary, base);
      position: relative;
      margin-top: 30px;
      &::after {
        content: '';
        background-color: rgba(36, 188, 170, 0.51);
        height: 15px;
        width: 153px;
        position: absolute;
        left: 176px;
        top: 30px;
      }
    }
    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 29px;
      letter-spacing: 0.02em;
      color: #131d24;
      margin-top: 30px;
    }
    a {
      @include Button;
      margin-top: 0;
      &:hover {
        background: color(primary, base);
      }
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
      li {
        font-weight: 460;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #1e1e20;
        padding-bottom: 20px;
      }
    }
    img {
      margin-top: 30px;
      @include responsive-for(md){
        width: 100%;
      }
    }
  }
  &__runbusiness {
    background-color: #e4faf7;
    padding-top: 40px;
    padding-bottom: 60px;
    h4 {
      font-weight: 700;
      font-size: 30px;
      line-height: 48px;
      color: color(secondary, base);
      position: relative;
      margin-top: 20px;
      &::after {
        content: '';
        background-color: rgba(36, 188, 170, 0.51);
        height: 12px;
        width: 70px;
        position: absolute;
        right: 272px;
        top: 30px;
        @include responsive-for(md){
          right: 453px;
        }
      }
    }
    p {
      margin-top: 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.02em;
      color: #131d24;
    }
    img {
      width: 100%;
    }
  }
  &__readerp {
    background-color: #fafafa;
    padding: 72px 0;
    text-align: center;
    h4 {
      @include headingh4;
      &::after {
        @include pseudoElement;
        width: 170px;
        top: 30px;
        right: 505px;
        @include responsive-for(md){
          right: 199px;
        }
        @include responsive-for(xs){
          right: 27px;
        }
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #131d24;
      width: 48%;
      margin: 18px auto 30px auto;
      @include responsive-for(md){
        width: 100%;
      }
      @include responsive-for(xs){
        width: 100%;
      }
    }
    a {
      @include Button;
      margin-top: 0;
      &:hover {
        background: color(primary, base);
      }
    }
  }
  &__testimonials {
    margin: 60px 0 100px 0;
    h4 {
      @include headingh4;
      text-align: center;
      &::after {
        content: '';
        background-color: rgba(36, 188, 170, 0.51);
        height: 12px;
        width: 55px;
        position: absolute;
        right: 411px;
        top: 30px;
        @include responsive-for(md){
          right: 265px;
        }
        @include responsive-for(xs){
          right: 277px;
        }
      }
    }
    &--para {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #131d24;
      width: 50%;
      margin: 18px auto 60px auto;
      text-align: center;
      @include responsive-for(md){
        width: 100%;
      }
    }
    &--content {
      background: #ecf1f5;
      border-radius: 5px;
      width: 97%;
      padding: 56px 85px;
      text-align: center;
      position: relative;
    }
    &--content-quote {
      position: absolute;
      color: color(primary, base);
      top: 40px;
      left: 20px;
      font-size: 35px;
    }
    &--content-para {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.02em;
      color: #333333;
    }
    &--content-name {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      color: #0d0d0d;
      margin-bottom: 0;
      margin-top: 40px;
    }
    &--content-post {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #0d0d0d;
    }
  }
}
