.home {
 .homefinancetabs {
    background: #fafafa;
    padding: 60px 0 50px 0;
  }
}
.tabcontents {
  .nav {
    display: flex;
    justify-content: space-between;
  }
  .nav-tabs {
    border-bottom: none;
  }
  .nav-tabs .nav-link {
    background-color: #f3f3f3;
    border-radius: 12px;
    padding: 9px 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.02em;

    color: #5a6166;
  }
  .nav-tabs .nav-link.active {
    color: color(primary, base) !important;
    background-color: #f3f3f3;
    border: none;
  }
  .nav-tabs .nav-link.active:hover {
    color: color(primary, base) !important;
    background-color: #f3f3f3;
    border: none;
  }
  .navtab-heading{
    @include headingh4;
    margin-top: 68px;
    &::after {
      @include pseudoElement;
      width: 117px;
      top: 30px;
      left: 0;
    }
  }
  .navtab-paragraph {
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: #131d24;
    margin-top: 30px;
    width: 80%;
    margin-bottom: 40px;
    @include responsive-for(md){
      width: 100%;
    }
  }
}
.erpnee-bankingfinance{
    background: #fff;
    padding: 50px 20px;
}
button{
margin-top: 10px !important;
}
.supply-planning,.nav-item{
  margin-left: 8px !important;
}